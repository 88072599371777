import localforage from 'localforage'

import type { NavigationGuard } from 'vue-router'

export function useErrorGuard(): NavigationGuard {
  return async () => {
    try {
      await localforage.getItem('')
      return { path: '/' }
    } catch {
      // do nothing
      return true
    }
  }
}
