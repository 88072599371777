import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { constants } from '@/globals'
import { useAuthenticationGuard } from '@/shared/authentication'

export default {
  path: '/:customerId/api-documentation',
  beforeEnter: [
    useAuthenticationGuard(),
  ],
  component: retriableLazyImport(() => import('./pages/PublicApiDocumentation.vue')),
  name: constants.ROUTES_API_DOCUMENTATION,
} as RouteRecordRaw
