import 'highlight.js/styles/stackoverflow-light.min.css'
import type { Directive } from 'vue'
import type { UserType } from '@/api'

import { useUserStore } from '@/shared/authorization'

export type PermissionOptions = {
  scope?: string
  minType?: `${UserType}`
}

export const vPermission: Directive<HTMLElement, PermissionOptions> = {
  mounted(el, { value, oldValue }) {
    const { hasScope, hasMinType } = useUserStore()

    if (value && value !== oldValue) {
      if ((value.scope && !hasScope(value.scope)) || (value.minType && !hasMinType(value.minType))) {
        const comment = document.createComment('v-permission')

        el.parentNode?.replaceChild(comment, el)
      }
    }
  },
}
