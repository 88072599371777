import localforage from 'localforage'

import type { NavigationGuard } from 'vue-router'

import { constants } from '@/globals'

export function useBrowserGuard(): NavigationGuard {
  return async () => {
    try {
      await localforage.getItem('')
    } catch {
      return { name: constants.ROUTES_STORAGE_ERROR }
    }
  }
}
