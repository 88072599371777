import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { constants } from '@/globals'
import { type SingleCustomer, useCustomerStore } from '@/shared/authentication'

const redirect = async () => {
  const { getCustomers } = useCustomerStore()

  const customers: SingleCustomer[] = await getCustomers()

  return { name: constants.ROUTES_MODULES, params: { customerId: customers[0].id } }
}

export default {
  path: '/service',
  component: retriableLazyImport(() => import('./layout/ServiceLayout.vue')),
  redirect: {
    name: constants.ROUTES_SERVICE_CUSTOMERS,
  },
  children: [
    {
      path: 'customers',
      component: retriableLazyImport(() => import('./pages/overview/Overview.vue')),
      name: constants.ROUTES_SERVICE_CUSTOMERS,
      meta: {
        permission: {
          minType: 'Tenant',
          redirect,
        },
      },
    },
    {
      path: 'firmware',
      component: retriableLazyImport(() => import('./pages/firmware/Firmware.vue')),
      name: constants.ROUTES_SERVICE_FIRMWARE,
      meta: {
        permission: {
          minType: 'Tenant',
          redirect,
        },
      },
    },
    {
      path: 'tariffs',
      component: retriableLazyImport(() => import('./pages/tariffs/Tariffs.vue')),
      name: constants.ROUTES_SERVICE_TARIFFS,
      meta: {
        permission: {
          minType: 'Tenant',
          redirect,
        },
      },
    },
    {
      path: 'debug/consumption',
      component: retriableLazyImport(() => import('../../shared/consumption/debug/DataConsumptionCalculator.vue')),
      name: constants.ROUTES_CONSUMPTION_DEBUG,
      meta: {
        permission: {
          minType: 'Tenant',
          redirect,
        },
      },
    },
    {
      path: 'debug/errors',
      component: retriableLazyImport(() => import('@/shared/error-handling/debug/ErrorTester.vue')),
      name: constants.ROUTES_ERROR_DEBUG,
      meta: {
        permission: {
          minType: 'Tenant',
          redirect,
        },
      },
    },
    {
      path: 'debug/onboarding',
      component: retriableLazyImport(() => import('@/shared/module/debug/DebugOnboardingFlow.vue')),
      name: constants.ROUTES_ONBOARDING_DEBUG,
      meta: {
        permission: {
          minType: 'Tenant',
          redirect,
        },
      },
    },
    {
      path: 'debug/rtu-serial',
      component: retriableLazyImport(() => import('@/shared/module/debug/DebugRTUSerial.vue')),
      name: constants.ROUTES_SERIAL_DEBUG,
      meta: {
        permission: {
          minType: 'Tenant',
          redirect,
        },
      },
    },
  ],
} as RouteRecordRaw
