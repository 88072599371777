import localforage from 'localforage'
import { storeToRefs } from 'pinia'

import type { NavigationGuard } from 'vue-router'

import { constants } from '@/globals'
import { useAuthenticationStore, useCustomerStore } from '@/shared/authentication'
import { useUserStore } from '@/shared/authorization'
import { useCustomerId } from '@/shared/composables/useCustomerId.ts'

export function useAuthenticationGuard(): NavigationGuard {
  return async (to) => {
    const { checkAuth0Session, tokenExpirationTimestamp } = useAuthenticationStore()
    const userStore = useUserStore()
    const { getMe, hasScope, hasMinType } = userStore
    const { user } = storeToRefs(userStore)
    const { setActiveCustomer, clearActiveCustomer } = useCustomerStore()
    const redirect = await localforage.getItem<string>(constants.LOCAL_STORAGE_AUTHENTICATION_REDIRECT)

    async function updateActiveCustomerByParams() {
      if (!to.params.customerId) {
        clearActiveCustomer()
        return
      }

      return setActiveCustomer(to.params.customerId as string)
    }

    async function updateCurrentUser() {
      if (user.value) {
        return
      }

      return getMe()
    }

    async function clearRedirect() {
      if (redirect) {
        return localforage.removeItem(constants.LOCAL_STORAGE_AUTHENTICATION_REDIRECT)
      }
    }

    try {
      if (!tokenExpirationTimestamp || Date.now() >= tokenExpirationTimestamp) {
        await checkAuth0Session()
      }

      await Promise.all([
        clearRedirect(),
        updateActiveCustomerByParams(),
        updateCurrentUser(),
      ])

      if (to.meta.permission) {
        if ((to.meta.permission.scope && !hasScope(to.meta.permission.scope)) || (to.meta.permission.minType && !hasMinType(to.meta.permission.minType))) {
          if (typeof to.meta.permission?.redirect === 'function') {
            return await to.meta.permission?.redirect()
          }

          const customerId = useCustomerId()

          return to.meta.permission?.redirect ?? {
            name: constants.ROUTES_MODULES,
            params: { customerId },
          }
        }

        return true
      }

      if (redirect) {
        return { path: redirect }
      }
    } catch {
      return false
    }
  }
}
