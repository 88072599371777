import { ComposableDependencyError } from '@gcx-si/vue-components/ComposableDependencyError'

import { useCustomerStore } from '@/shared/authentication'

export function useCustomerId() {
  const { customer } = useCustomerStore()

  if (!customer?.id) {
    throw new ComposableDependencyError('Customer ID not available.')
  }

  return customer.id
}
