import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { constants } from '@/globals'

export default {
  path: 'installations',
  meta: {
    navigation: {
      labelKey: 'app.installations.pages.overview.title',
      targetName: constants.ROUTES_INSTALLATIONS,
    },
  },
  children: [
    {
      path: '',
      component: retriableLazyImport(() => import('./pages/Overview.vue')),
      name: constants.ROUTES_INSTALLATIONS,
      meta: {
        permission: {
          scope: 'read:system',
        },
      },
    },
    {
      path: ':installationId',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_INSTALLATION,
      meta: {
        permission: {
          scope: 'read:system',
        },
      },
    },
  ],
} as RouteRecordRaw
