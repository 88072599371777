export type GlobalConfig = {
  SENTRY_ENVIRONMENT: 'dev'|'stage'|'prod'
  BACKEND_URL: string
  AUTH0_CLIENT_ID: string
  AUTH0_DOMAIN: string
  AUTH0_AUDIENCE: string
  IS_DEV_SERVER?: boolean
}

declare global {
  interface Window {
    __ENV__: GlobalConfig
  }
}

export const config: GlobalConfig = window.__ENV__
