import { setUser as setSentryUser } from '@sentry/browser'
import axios from 'axios'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, shallowRef, watch } from 'vue'

import type { RoleResponse, UpdateUserRequest, UserResponse } from '@/api'

import { UserType } from '@/api'
import { useCustomerStore } from '@/shared/authentication'

// Smaller index means more permissions
const TYPES: `${UserType}`[] = [UserType.GLOBAL_ADMIN, UserType.TENANT, UserType.CUSTOMER]

export const useUserStore = defineStore('UserStore', () => {
  const user = ref<UserResponse>()
  const { customer } = storeToRefs(useCustomerStore())
  const scopes = computed(() => user.value?.role.scopes)
  const roles = shallowRef<RoleResponse[]>()

  async function getCustomerUserRoles(id: string) {
    const { data } = await axios.get<RoleResponse[]>(`/v1/customers/${id}/roles`)

    return data
  }

  async function getUser(id: string) {
    const { data } = await axios.get<UserResponse>(`/v1/users/${id}`)

    return data
  }

  async function deleteUser(id: string) {
    await axios.delete(`/v1/users/${id}`)
  }

  async function updateUser(id: string, payload: UpdateUserRequest) {
    const { data: updatedUser } = await axios.patch<UserResponse>(`/v1/users/${id}`, payload)

    if (updatedUser.id === user.value?.id) {
      user.value = updatedUser
    }

    return updatedUser
  }

  async function getMe() {
    const { data } = await axios.get<UserResponse>('/v1/users/me')

    user.value = data

    return data
  }

  async function ensureRolesLoaded() {
    if (!roles.value) {
      roles.value = await getCustomerUserRoles(customer.value?.id as string)
    }

    return roles.value
  }

  function hasScope(scope: string) {
    return scopes.value?.includes(scope)
  }

  function hasMinType(type?: `${UserType}`) {
    return (type && user.value?.type && TYPES.indexOf(user.value.type) <= TYPES.indexOf(type)) ?? false
  }

  watch(user, (newUser) => {
    if (!newUser) {
      setSentryUser(null)
      return
    }

    setSentryUser({
      id: newUser.id,
      username: newUser.name,
      email: newUser.email,
      role: newUser.role.name,
      customerId: newUser.customerId,
    })
  })

  watch(customer, () => {
    roles.value = undefined
  })

  return {
    user,
    scopes,
    roles,
    ensureRolesLoaded,
    getUser,
    deleteUser,
    updateUser,
    getMe,
    hasScope,
    hasMinType,
  }
})
