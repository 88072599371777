import { createRouter, createWebHistory } from 'vue-router'

import type { RouteRecordRaw, NavigationGuardReturn } from 'vue-router'
import type { UserType } from '@/api'

import {
  ApiDocumentationRoutes,
  OnboardingRoutes,
  ServiceRoutes
} from '@/apps'
import { constants } from '@/globals'
import { AuthenticationRoutes, useAuthenticationGuard } from '@/shared/authentication'
import { BrowserRoutes, useBrowserGuard } from '@/shared/browser'
import { CustomerRoutes } from '@/shared/layout/routes'

declare module 'vue-router' {
  interface RouteMeta {
    navigation?: {
      labelKey: string
      targetName: string
    }
    permission?: {
      scope?: string
      minType?: UserType
      redirect?: NavigationGuardReturn | (() => NavigationGuardReturn) | (() => Promise<NavigationGuardReturn>)
    }
  }
}

const NoMatchRoutes: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  redirect: { name: constants.ROUTES_SERVICE_CUSTOMERS },
}

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes: [
    // UI routes
    ApiDocumentationRoutes,
    ServiceRoutes,
    OnboardingRoutes,
    CustomerRoutes,

    // Utility routes
    AuthenticationRoutes,
    BrowserRoutes,
    NoMatchRoutes,
  ],
})

router.beforeEach(useBrowserGuard())
router.beforeEach(useAuthenticationGuard())

export default router
