import type { RouteRecordRedirect } from 'vue-router'

import { useAuthenticationStore } from '@/shared/authentication'

export default {
  path: '/callback',
  redirect: () => {
    const { callback } = useAuthenticationStore()

    callback()

    return { path: '/' }
  },
} as RouteRecordRedirect
